import React from 'react';
import './tabledata.css';

function TableData({ data, headerTag, activeTab }) {
    const setWhite = () =>{
        
    }
    return (
        <div className='table-container'>
            <table className='rounded-corners'>
                <thead>
                    {headerTag === 'Task Type' ? (
                        <tr onScroll={setWhite}>
                            <th>{headerTag}</th>
                            <th>New</th>
                            <th>Mod</th>
                            <th>Overdue</th>
                            <th>Total Work Count</th>
                        </tr>
                    ) : (
                        <tr>
                            <th>{headerTag}</th>
                            {activeTab === 'Work Type' && <th>Work Type</th>}
                            <th>Completed</th>
                            <th>Ongoing</th>
                            <th>Upcoming</th>
                            <th>Total Work Count</th>
                        </tr>
                    )}
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={index} rowSpan={row.RowSpan}>
                            {Object.keys(row).map((key, i) =>
                                row[key] !== null ? <td key={i}>{row[key]}</td> : <td key={i}></td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default TableData;
