import React, { useState, useEffect } from 'react';
import './insights.css';
import InsightsCard from './insightsCard';
import config from '../config';
import Table from './table';

function Insights() {
  const [selectedCards, setSelectedCards] = useState([]);
  const [activeTab, setActiveTab] = useState('Medium');
  const [cardData, setCardData] = useState(null);
  let URL = 'https://alembicdigilabs.in/digilabs/mis/dtable';
  

  const fetchData = async (val) => {
    try {
      const response = await fetch(URL + `?viewall=${val}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      let Data = {
        ...result,
        overall_total: { ...result.overall_total, title: 'Overall Total', color: 'white', footerColor: 'rgba(255, 255, 255, 0.12)' },
        artwork: { ...result.artwork, title: 'Artwork', color: ' rgba(43, 99, 77, 1)', footerColor: 'rgba(217, 237, 229, 0.4)', icon: config.artwork, hoverColor: 'rgba(43, 99, 77, 0.21)', },
        video: { ...result.video, title: 'Video', color: 'rgba(172, 56, 56, 1)', footerColor: 'rgba(255, 233, 233, 0.4)', icon: config.video, hoverColor: 'rgba(172, 56, 56, 0.21)' },
        content_writing: { ...result.content_writing, title: 'Content Writing', color: 'rgba(92, 92, 92, 1)', footerColor: 'rgba(229, 229, 229, 0.4)', icon: config.content, hoverColor: 'rgba(92, 92, 92, 0.21)' },
        web_application: { ...result.web_application, title: 'Web Application', color: 'rgba(73, 26, 203, 1)', footerColor: 'rgba(239, 234, 250, 0.4)', icon: config.web, hoverColor: 'rgba(73, 26, 203, 0.21)' },
        art_direction: { ...result.art_direction, title: 'Art Direction', color: 'rgba(189, 96, 3, 1)', footerColor: 'rgba(255, 245, 224, 0.4)', icon: config.artDirection, hoverColor: 'rgba(73, 26, 203, 0.21)' },
        shoot: { ...result.shoot, title: 'Shoot', color: 'rgba(4, 129, 174, 1)', footerColor: 'rgba(229, 241, 255, 0.4)', icon: config.shoot, hoverColor: 'rgba(4, 129, 174, 0.21)' },
        clm: { ...result.clm, title: 'CLM', color: 'rgba(151, 2, 104, 1)', footerColor: 'rgba(255, 233, 248, 0.4)', icon: config.clm, hoverColor: 'rgba(151, 2, 104, 0.21)' },
      };
      setCardData(Data);
    } catch (err) {
      // setError(err.message);
    } finally {
      // setLoading(false);
    }
  };


  const handleCardSelect = (card) => {
    setSelectedCards((prevSelectedCards) => {
      if (!card) {
        return prevSelectedCards;;
      }
      if (prevSelectedCards.find((selected) => selected.title === card?.title)) {
        // If card is already selected, deselect it by removing it from the array
        return prevSelectedCards.filter((selected) => selected.title !== card?.title);
      } else {
        // If card is not selected, add it to the array
        return [...prevSelectedCards, card];
      }
    });
  };

  useEffect(() => {
    console.log(activeTab);
    fetchData(true);
    if (selectedCards.length) {
      console.log("Selected Cards:", selectedCards.map(card => card.title));
    } else {
      console.log("No cards selected");
    }
  }, [selectedCards, activeTab]);

  return (
    <div className='insights transition-container'>
      <div style={{overflowY:'scroll', maxHeight:'750px'}}>
        <header>
          <p>Digilabs MIS</p>
        </header>
        <div className='card_row'>
          <InsightsCard data={cardData?.artwork} onCardSelect={handleCardSelect} selectedCards={selectedCards} updatedCardData={cardData?.art_direction}/>
          <InsightsCard data={cardData?.video} onCardSelect={handleCardSelect} selectedCards={selectedCards} updatedCardData={cardData?.shoot}/>
          <InsightsCard data={cardData?.content_writing} onCardSelect={handleCardSelect} selectedCards={selectedCards} />
          <InsightsCard data={cardData?.web_application} onCardSelect={handleCardSelect} selectedCards={selectedCards} updatedCardData={cardData?.clm}/>
        </div>
        <div style={{ marginTop: '1rem' }}>
          <Table onTabChange={(tab) => { setActiveTab(tab) }} selectedCards={selectedCards} />
        </div>
      </div>
      <div className='filter_section'>
        <InsightsCard data={cardData?.overall_total} />
        <div className='filter_selection_wrapper'>
          <div className='filter_selection_item'>
            <img src={config.verticals} alt='' />
            <p>Verticals</p>
            <img src={config.downArrow} alt='' style={{ textAlign: 'right' }} />
          </div>
          <div className='filter_selection_item'>
            <img src={config.calenderBlack} alt='' />
            <p>Date</p>
            <img src={config.downArrow} alt='' />
          </div>
          <div className='filter_selection_item'>
            <img src={config.settings} alt='' />
            <p>Project Status</p>
            <img src={config.downArrow} alt='' />
          </div>
          <div className='filter_selection_item'>
            <img src={config.artist} alt='' />
            <p>Artist</p>
            <img src={config.downArrow} alt='' />
          </div>
        </div>
        <div style={{ height: '20%', width: '90%' }}></div>
      </div>
    </div>
  )
}

export default Insights
