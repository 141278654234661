import React, { useEffect, useState } from 'react';
import './dashboard.css';
import config from '../config';
import Card from './card';
import DatePicker from 'react-datepicker';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import * as XLSX from 'xlsx';

function Dashboard() {

    const [data, setData] = useState(null);
    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);
    const [viewAll, setViewAll] = useState(false);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [dateFilter, setDateFilter] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const clearDateFilter = () => {
        setDateFilter(false);
        setFromDate(null);
        setToDate(null);
        fetchData(viewAll);
    }
    
    const handleApply = async () => {
        setShowPopup(false);

        const monthYear1 = fromDate.toLocaleString('de-DE', { day:'numeric', month: 'short', year: 'numeric' });
        const monthYear2 = toDate.toLocaleString('de-DE', { day:'numeric',month: 'short', year: 'numeric' });
        console.log(monthYear1, monthYear2, fromDate); // Output: "Oct 2024"

        
        const formattedFromDate = fromDate ? fromDate.toISOString().split('T')[0] : '';
        const formattedToDate = toDate ? toDate.toISOString().split('T')[0] : '';

        try{
            const response = await fetch(URL + `?viewall=${viewAll}&start_date=${formattedFromDate}&end_date=${formattedToDate}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            const updatedData = {
                ...result,
                overall_total: { ...result.overall_total, title: 'Overall Total', color: 'white', footerColor: 'rgba(255, 255, 255, 0.12)' },
                artwork: { ...result.artwork, title: 'Artwork', color: ' rgba(43, 99, 77, 1)', footerColor: 'rgba(217, 237, 229, 0.4)', icon: config.artwork },
                video: { ...result.video, title: 'Video', color: 'rgba(172, 56, 56, 1)', footerColor: 'rgba(255, 233, 233, 0.4)', icon: config.video },
                content_writing: { ...result.content_writing, title: 'Content Writing', color: 'rgba(92, 92, 92, 1)', footerColor: 'rgba(229, 229, 229, 0.4)', icon: config.content },
                web_application: { ...result.web_application, title: 'Web Application', color: 'rgba(73, 26, 203, 1)', footerColor: 'rgba(239, 234, 250, 0.4)', icon: config.web },
                ...(result.art_direction && {art_direction: { ...result.art_direction, title: 'Art Direction', color: 'rgba(189, 96, 3, 1)', footerColor: 'rgba(255, 245, 224, 0.4)', icon: config.artDirection }}),
                ...(result.shoot && {shoot: { ...result.shoot, title: 'Shoot', color: 'rgba(4, 129, 174, 1)', footerColor: 'rgba(229, 241, 255, 0.4)', icon: config.shoot }}),
                ...(result.clm && {clm: { ...result.clm, title: 'CLM', color: 'rgba(151, 2, 104, 1)', footerColor: 'rgba(255, 233, 248, 0.4)', icon: config.clm }}),
            };
            setData(updatedData);
            setDateFilter(true);
        } catch{

        } finally{

        }
    };

    const renderCustomHeader = ({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
      }) => (
        <div className="custom-header">
          <FaChevronLeft 
            className={`nav-icon ${prevMonthButtonDisabled ? 'disabled' : ''}`} 
            onClick={decreaseMonth} 
            disabled={prevMonthButtonDisabled}
          />
          <span className="month-label">
            {date.toLocaleString('default', { month: 'long' })} {date.getFullYear()}
          </span>
          <FaChevronRight 
            className={`nav-icon ${nextMonthButtonDisabled ? 'disabled' : ''}`} 
            onClick={increaseMonth} 
            disabled={nextMonthButtonDisabled}
          />
        </div>
      );

    let URL = 'https://alembicdigilabs.in/digilabs/mis/dtable';
    useEffect(() => {
        fetchData(false);
    }, []);

    useEffect(() => {
        !viewAll ? fetchData(viewAll) : filterByViewAll(viewAll)
    }, [viewAll]);

    const fetchData = async (val) => {
        try {
            const response = await fetch(URL + `?viewall=${val}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            const updatedData = {
                ...result,
                overall_total: { ...result.overall_total, title: 'Overall Total', color: 'white', footerColor: 'rgba(255, 255, 255, 0.12)' },
                artwork: { ...result.artwork, title: 'Artwork', color: ' rgba(43, 99, 77, 1)', footerColor: 'rgba(217, 237, 229, 0.4)', icon: config.artwork },
                video: { ...result.video, title: 'Video', color: 'rgba(172, 56, 56, 1)', footerColor: 'rgba(255, 233, 233, 0.4)', icon: config.video },
                content_writing: { ...result.content_writing, title: 'Content Writing', color: 'rgba(92, 92, 92, 1)', footerColor: 'rgba(229, 229, 229, 0.4)', icon: config.content },
                web_application: { ...result.web_application, title: 'Web Application', color: 'rgba(73, 26, 203, 1)', footerColor: 'rgba(239, 234, 250, 0.4)', icon: config.web },
            };
            setData(updatedData);
        } catch (err) {
            // setError(err.message);
        } finally {
            // setLoading(false);
        }
    };

    async function filterByViewAll(val) {
        let formattedFromDate;
        let formattedToDate;
        if(fromDate){
            formattedFromDate = fromDate ? fromDate.toISOString().split('T')[0] : '';
            formattedToDate = toDate ? toDate.toISOString().split('T')[0] : '';
        }
        try {     
            const response = await fetch(URL + `?viewall=${val}` + (fromDate ? `&start_date=${formattedFromDate}&end_date=${formattedToDate}` : ''));
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            var cookies = document.cookie.split("=");
            console.log(cookies);
            
            const result = await response.json();
            // convertJsonToExcel(result);
            const updatedData = {
                ...result,
                overall_total: { ...result.overall_total, title: 'Overall Total', color: 'white', footerColor: 'rgba(255, 255, 255, 0.12)' },
                artwork: { ...result.artwork, title: 'Artwork', color: ' rgba(43, 99, 77, 1)', footerColor: 'rgba(217, 237, 229, 0.4)', icon: config.artwork },
                video: { ...result.video, title: 'Video', color: 'rgba(172, 56, 56, 1)', footerColor: 'rgba(255, 233, 233, 0.4)', icon: config.video },
                content_writing: { ...result.content_writing, title: 'Content Writing', color: 'rgba(92, 92, 92, 1)', footerColor: 'rgba(229, 229, 229, 0.4)', icon: config.content },
                web_application: { ...result.web_application, title: 'Web Application', color: 'rgba(73, 26, 203, 1)', footerColor: 'rgba(239, 234, 250, 0.4)', icon: config.web },
                art_direction: { ...result.art_direction, title: 'Art Direction', color: 'rgba(189, 96, 3, 1)', footerColor: 'rgba(255, 245, 224, 0.4)', icon: config.artDirection },
                shoot: { ...result.shoot, title: 'Shoot', color: 'rgba(4, 129, 174, 1)', footerColor: 'rgba(229, 241, 255, 0.4)', icon: config.shoot },
                clm: { ...result.clm, title: 'CLM', color: 'rgba(151, 2, 104, 1)', footerColor: 'rgba(255, 233, 248, 0.4)', icon: config.clm },
            };
            setData(updatedData);
        } catch (err) {
            // setError(err.message);
        } finally {
            // setLoading(false);
        }
    }

    const handleViewAll = () => {
        setIsTransitioning(true);
        setTimeout(() => {
            setViewAll(!viewAll);
            setIsTransitioning(false);
        }, 500);
    };

    function formatDate(date) {
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
    
        // Add suffix for the day (st, nd, rd, th)
        const daySuffix = (day) => {
            if (day > 3 && day < 21) return 'th'; // For days 4 - 20
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        };
    
        return (
            <>
                {day}<sup>{daySuffix(day)}</sup> {month} {year}
            </>
        );
    }


    const convertJsonToExcel = (json) => {
        // Convert JSON to a two-dimensional array for Excel format
        const headers = ["Category", "Work Count", "Outcome", "Upcoming", "Ongoing", "Completed"];
        const rows = [headers];
    
        // Populate rows with data from JSON
        for (const [category, data] of Object.entries(json)) {
            rows.push([
                category,
                data.work_count,
                data.outcome,
                data.project_status.upcoming,
                data.project_status.ongoing,
                data.project_status.completed
            ]);
        }
    
        // Create a workbook and add a sheet with rows
        const worksheet = XLSX.utils.aoa_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
        // Export the workbook to Excel file
        XLSX.writeFile(workbook, "data.xlsx");
    };

    return (
        <div className='dashboard'>
            <header>
                <p>Digilabs MIS</p>
            </header>

            <div className='sub-header'>
                <div>
                    { !dateFilter && <span className='sub-header-title'>YTD</span>}
                    { dateFilter && ( 
                        <div className='date_filter_wrapper'>
                            <span>{formatDate(fromDate)} - {formatDate(toDate)}</span>
                            <img src={config.filter_close} alt='close' onClick={clearDateFilter} style={{height: '17px'}}/>
                        </div>
                    )}
                </div>
                <div className='header-action-wrapper'>
                    <div className='header-action-1'>
                        <input type="checkbox" id='filter' onClick={handleViewAll} />
                        <label htmlFor='filter'>View All</label>
                    </div>
                    <div className='header-action-2' onClick={togglePopup}>
                        <span>Filters</span>
                        <img src={config.filter} alt='' />
                    </div>

                    {showPopup && (
                        <div className="filter-popup">
                            <div className="filter-date-pickers">
                                <div className="datepicker-wrapper">
                                    <img src={config.calendar} alt='calendar' className="calendar-1"/>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={(date) => setFromDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="From"
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={5}
                                        renderCustomHeader={renderCustomHeader}
                                    />
                                </div>

                                <div className="datepicker-wrapper">
                                    <img src={config.calendar} alt='calendar' className="calendar-2"/>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={(date) => setToDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="To"
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={5}
                                        renderCustomHeader={renderCustomHeader}
                                    />
                                </div>
                            </div>
                            <div className="apply-btn" onClick={handleApply}>
                                Apply
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {data && (
                <div className={`transition-container ${isTransitioning ? '' : viewAll ? 'show-all' : 'show-one'}`} style={{width: '100%'}}>
                    {viewAll ? (
                        <div className='anotherBody'>
                            {Object.keys(data).map(key => (
                                <Card key={key} data={data[key]} />
                            ))}
                        </div>
                    ) : (
                        <div className='body'>
                            <Card key="overall_total" data={data['overall_total']} />
                            <div className="other-items">
                                {Object.keys(data).filter(key => key !== 'overall_total').map(key => (
                                    <Card key={key} data={data[key]} />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Dashboard;
