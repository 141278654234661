import React, { useState, useEffect } from 'react';
import config from '../config';
import './table.css'
import TableData from './tabledata';

function Table({ onTabChange, selectedCards }) {
    const tabs = ['Medium', 'Division', 'Artist', 'Work Type', 'Task Type'];
    const [activeTab, setActiveTab] = useState('Medium');
    const [tableData, setTableData] = useState([]);
    const [headerTag, setHeaderTag] = useState("Vertical");

    const transformData = (apiResponse, type) => {
        const transformed = [];

        switch (type) {
            case "dtable":
                setHeaderTag("Vertical"); // Default tag
                for (const [key, value] of Object.entries(apiResponse)) {
                    transformed.push({
                        Vertical: key.charAt(0).toUpperCase() + key.slice(1),
                        Completed: parseInt(value.project_status.completed) || null,
                        Ongoing: parseInt(value.project_status.ongoing) || null,
                        Upcoming: parseInt(value.project_status.upcoming) || null,
                        "Total Work Count": parseInt(value.work_count) || null,
                    });
                }
                break;

            case "division":
                setHeaderTag("Division"); // Update header tag
                apiResponse.forEach((item) => {
                    transformed.push({
                        Vertical: item.division,
                        Completed: parseInt(item.project_status.completed) || null,
                        Ongoing: parseInt(item.project_status.ongoing) || null,
                        Upcoming: parseInt(item.project_status.upcoming) || null,
                        "Total Work Count": parseInt(item.total_count) || null,
                    });
                });
                break;

            case "artist":
                setHeaderTag("Artist"); // Update header tag
                apiResponse.forEach((item) => {
                    transformed.push({
                        Vertical: item.artist_name,
                        Completed: parseInt(item.project_status.completed) || null,
                        Ongoing: parseInt(item.project_status.ongoing) || null,
                        Upcoming: parseInt(item.project_status.upcoming) || null,
                        "Total Work Count": parseInt(item.total_count) || null,
                    });
                });
                break;
            case "worktype":
                setHeaderTag("Vertical"); // Update header tag
                apiResponse.forEach((category) => {
                    const { work_category, work_type, work_count } = category;

                    // Flatten the work_type array and include rowspan for the first row
                    work_type.forEach((type, index) => {
                        transformed.push({
                            Vertical: index === 0 ? work_category : null, // Set Vertical only for the first row
                            WorkType: type.qText, // New, Mod, Overdue
                            Completed: parseInt(type.project_status.completed) || null,
                            Ongoing: parseInt(type.project_status.ongoing) || null,
                            Upcoming: parseInt(type.project_status.upcoming) || null,
                            "Total Work Count": index === 0 ? parseInt(work_count) : null,
                            // RowSpan: index === 0 ? work_type.length : null, // Set RowSpan only for the first row
                        });
                    });
                });
                break;

            case "tasktype":
                setHeaderTag("Task Type"); // Update header tag
                apiResponse.forEach((item) => {
                    transformed.push({
                        Vertical: item.task_type,
                        New: parseInt(item.work_type.new) || null,
                        Mod: parseInt(item.work_type.mod) || null,
                        Overdue: parseInt(item.work_type.overdue) || null,
                        "Total Work Count": parseInt(item.total_work_count) || null,
                    });
                });
                break;

            default:
                break;
        }

        return transformed;
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                let url = "";
                let type = "";

                switch (activeTab) {
                    case "Medium":
                        url = "https://alembicdigilabs.in/digilabs/mis/dtable";
                        type = "dtable";
                        break;
                    case "Division":
                        url = "https://alembicdigilabs.in/digilabs/mis/division";
                        type = "division";
                        break;
                    case "Artist":
                        url = "https://alembicdigilabs.in/digilabs/mis/artist";
                        type = "artist";
                        break;
                    case "Work Type":
                        url = "https://alembicdigilabs.in/digilabs/mis/worktype";
                        type = "worktype";
                        break;
                    case "Task Type":
                        url = "https://alembicdigilabs.in/digilabs/mis/tasktype";
                        type = "tasktype";
                        break;
                    default:
                        console.error("Invalid activeTab value");
                        return;
                }

                console.log(selectedCards.map(item=>item), typeof selectedCards)
                let finalUrl = selectedCards.length > 0
                    ? `${url}?category=${selectedCards.map(item => item.title.replace(/\s+/g, '_').toLowerCase()).join(',')}`
                    : url;
                const response = await fetch(finalUrl);
                const data = await response.json();
                const formattedData = transformData(data, type);
                setTableData(formattedData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [activeTab, selectedCards]);

    return (
        <div>
            <div className='action_wrapper'>
                <div className='tabs'>
                    {tabs.map((item, index) => {
                        return <span
                            className={`${item === activeTab ? 'activeTab' : 'inactive'}`} key={index}
                            onClick={() => { setActiveTab(item); onTabChange(item); }}>
                            {item}</span>
                    })}
                </div>
                <div className='downloadFilter_section'>
                    <div className='header-action-1' style={{ gap: '20px', width: '150px' }}>
                        <input type="checkbox" id='filter' />
                        <label htmlFor='filter'>Graph View</label>
                    </div>
                    <img src={config.download} alt='' style={{ width: '2rem' }} />
                </div>
            </div>
            <TableData data={tableData} headerTag={headerTag} activeTab={activeTab} />
        </div>
    )
}

export default Table;
